<template>
  <div id="app">
    <router-view></router-view>
    <div style="text-align: center;margin: 20px 0" >Copyright © 2023 异略信息科技（上海）有限公司 备案号：<a href="https://beian.miit.gov.cn/" target="_blank" >沪ICP备2022004110号-1</a></div>
  </div>

</template>

<script>
  export default  {
    name:  'App',
    metaInfo() {
      return {
        title: "异略信息科技（上海）有限公司",
        meta: [{
          name: 'keywords',
          content: '异略，门店管理，巡店，连锁门店，加盟管理，项目管理，合同管理，门店订货'
        },{
          name: 'description',
          content: '慧管店是门店数字化管理工具集'
        }]


      }
    }
  }
</script>
